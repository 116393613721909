.select-method-otp {
    height: 45vh;
    margin-top: 5%;
}

.icon-method-box {
    border: 2px solid var(--custom-primary-background-color);
    border-radius: 10px;
    padding: 16px;
    color: #2b2b2b;
    font-size: 16px;
    cursor: pointer;
}

.text-method {
    font-size: 20px;
    text-align: left;
    margin-left: 30px;
}

.icon-method-box.selected {
    border-color: var(--custom-primary-background-color);
    opacity: 1;
}

.icon-method-box.disabled {
    border-color: #9C9C9C;
}


@media (max-width: 600px) {
    .select-method-otp {
        height: 65vh;
        width: 90%;
    }

    .icon-method-box {
        padding: unset;
        margin-top: 30%;
        width: auto;
    }

    .icon-whatsapp {
        margin-top: unset !important;
    }
}

@media (min-width: 600px) and (max-width: 767px) {
    .select-method-otp {
        height: 65vh;
    }

    .icon-method-box {
        padding: unset;
        margin-top: 10%;
        width: auto;
    }

    .icon-whatsapp {
        margin-top: unset !important;
    }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
    .select-method-otp {
        height: 70vh;
        margin-top: 0%;
    }
  }