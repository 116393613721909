.rejected-image {
    width: 50%;
    height: auto;
    display: block;
    margin: 0 auto 20px auto;
}

.rejected-title {
    font-family: TT-Norms-Black;
    font-size: 28px;
    font-weight: 900;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--alternative-text-color);
    margin-top: 2%;
}

.rejected-subtitle {
    color: var(--alternative-text-color);
}

.rejected-success-box {
    height: 55vh;
}

@media (max-width: 450px) {
    .rejected-image {
        width: 180px;
        height: 176px;
    }
}

@media (max-width: 600px) {
    .rejected-title {
        margin-top: 5%;
    }
}

@media (max-width: 950px) and (orientation: landscape) {
    .rejected-image {
        width: 50%;
        height: auto;
    }
  }