.support-new-inputs-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.support-new-inputs-container > div {
    width: 100%;
}

.support-info-container .phone-number-form div label {
    margin-left: 0;
}

.support-info-container h3 {
    font-weight: bold;
}

.support-info-container .characters-counter{
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

@media screen and (max-width: 768px) {
    .support-new-inputs-container {
        flex-direction: column;
    }
    
    .support-info-container .phone-number-form div label {
        text-align: left;
        width: 100%;
    }
}