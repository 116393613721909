.form-view {
    padding: 1% 8%;
}

.welcome-container {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 10px;
    margin-top: 15px;
    padding-left: 0;
}

.welcome-text-container {
    text-align: left;
}

.welcome-container h2 {
    font-family: 'TT-Norms-Black';
}

.welcome-container p {
    margin-bottom: 0;
}

.form-view .action-buttons button {
    margin: 10px 0;
}

.form-view .action-buttons button,
.landscape-buttons-container button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-view .action-buttons button img,
.form-view .action-buttons button svg {
    width: 14px;
    height: 14px;
    margin-right: 27px;
}

.page-indicator {
    text-align: end;
    font-weight: bold;
    margin-top: 2%;
}

.progress {
    border-radius: 4px;
    padding: 0px;
    background-color: var(--card-background-color);
}

.progress-bar {
    background-color: var(--custom-primary-background-color);
}

@media (max-width: 600px) {
    .welcome-container {
        gap: 15px;
    }

    .form-view .action-buttons button img,
    .form-view .action-buttons button svg {
        margin-right: 12px;
    }
}