.textbox-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--card-background-color);
    padding: 15px;
    margin: 10px 0;
    padding: 25px;
}

.disabled-container {
    background-color: lightgray !important;
}

.disabled-container.advanced-form input,
.disabled-container.advanced-form textarea {
    background-color: lightgray;
}

.error-container {
    outline: 3px solid red;
}

.error-container.advanced-form {
    border: unset;
}

.error-container.advanced-form input,
.error-container.advanced-form textarea {
    border: 1px solid red;
}

.textbox-container>label {
    /* margin-left: 0px; */
    font-weight: bold;
}

.advanced-form label {
    font-weight: unset;
}

.textbox-container .text-input {
    width: 100%;
    border: 0px solid black;
    border-bottom: 1px solid black;
    outline: none;
    text-align: left;
    background: none;
    border-radius: none;
}

.error-container .text-input {
    border-bottom: 1px solid red;
}

.textbox-container .text-input:focus {
    border-bottom: 1px solid var(--custom-primary-background-color);
}

.textarea-form {
    width: 100%;
    resize: none;
}

.textarea-form:focus-visible {
    outline: 1px solid var(--custom-primary-background-color);
    border: 1px solid var(--custom-primary-background-color);
}

.letters-counter {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

@media (max-width: 600px) {
    .textbox-container {
        padding: 20px;
    }
}