.radiobutton-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--card-background-color);
    padding: 25px;
    margin: 10px 0;
    text-align: left;
}

.radiobutton-container > label {
    font-weight: bold;
}

.radiobutton-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.radiobutton-container form .form-check {
    width: 100%;
    display: flex;
    margin-bottom: 8px;
    align-items: center;
}

.radiobutton-container form .form-check input, .radiobutton-container form .radiobutton-html-container input  {
    width: 32px;
    height: 32px;
    border: 2px solid #151A35;
}

.advanced-form form {
    width: unset;
}

.advanced-form form .form-check input, .advanced-form form .radiobutton-html-container input  {
    width: 16px;
    height: 16px;
    border: 2px solid #151A35;
}

.advanced-form .form-check-input {
    margin-top: unset;
}

.radiobutton-container form .form-check input:checked {
    background-color: var(--custom-primary-background-color);
}
