.advanced-form, .advanced-form input {
    color: black;
}

.attachment-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--card-background-color);
    padding: 25px;
    margin: 10px 0;
}

.attachment-container.advanced-form {
    background-color: unset;
}

.attachment-container.advanced-form.error-container {
    color: red;
}

.attachment-container > label {
    margin-left: 0px;
    font-weight: bold;
}

.attachment-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.attachment-container form .form-check {
    width: 100%;
    display: flex;
    margin-bottom: 8px;
    align-items: center;
}

.attachment-container form .form-check input {
    width: 32px;
    height: 32px;
    border: 2px solid #151A35;
}

.file-button {
    margin-top: 5px;
    background-color: var(--custom-primary-background-color);
    border-color: var(--custom-primary-background-color);
    color: var(--custom-secondary-text-color);
    height: unset !important;
}

.file-button:hover, .file-button:active, .file-button:start {
    border-color: var(--custom-primary-background-color);
    background-color: var(--custom-primary-background-color-hover);
    color: var(--custom-secondary-text-color);
    filter: brightness(0.9);
}

.file-info-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.file-info-container img {
    width: 30px;
}

.file-info-container label:nth-child(2) {
    font-weight: bold;
    font-size: 22px;
}

.file-info-container img:nth-child(4) {
    margin-left: 10px;
    cursor: pointer;
}

.attachment-img {
    height: auto;
}

.attachment-container .attachment-img {
    width: auto;
}

.attachment-container.advanced-form .file-info-container img {
    width: 15px;
}

.attachment-container.advanced-form .file-info-container label:nth-child(2) {
    font-weight: bold;
    font-size: 16px;
}

@media (max-width: 600px) {
    .attachment-container form .form-check input {
        margin-right: 10px;
    }

    .file-info-container label {
        text-align: left;
        margin-left: 10px;
    }

    .file-info-container img {
        width: 16px;
    }
    
    .file-info-container label:nth-child(2) {
        font-weight: bold;
        font-size: 12px;
    }
}