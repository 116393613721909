.row-box {
  margin-top: 5vh;
  font-family: 'TT-Norms-Regular';
  font-size: 18px;
}

.title {
  font-weight: 600;
  font-family: 'TT-Norms-Black';
}

.height-card {
  height: 65vh;
}

.card {
  border: none;
  background: var(--card-background-color);
  display: grid;
  align-content: center;
}

.card-title {
  font-weight: 600;
  font-family: 'TT-Norms-Black';
}

.img-step {
  max-width: 100%;
}

.footer-onboarding-page-container {
  margin-top: 25px;
}

.form-check .form-check-input {
  float: unset;
}

label {
  font-family: 'TT-Norms-Regular';
  margin-left: 0.5rem;
}

.form-check-input:checked {
  border-color: var(--custom-primary-background-color);
}

.form-check-input:checked[type=checkbox] {
  background-image: url('./../../assets/svg/check.svg');
}

.form-check-input:focus {
  box-shadow: unset;
  border-color: var(--custom-primary-background-color);
}

.slick-dots li.slick-active button:before {
  color: var(--custom-primary-background-color) !important;
}

.slick-dots li button:before {
  font-size: 16px !important;
}

.footer-onboarding-page-container-mobile {
  margin-top: 25px;
}

.slick-dots {
  position: unset!important;
}

.footer-onboarding-page-container .form-check-input {
  border: 1px solid var(--custom-primary-background-color);
}

@media (max-width: 600px) {
  .height-card {
    height: 50vh;
  }

  .slick-next {
    right: unset !important;
  }

  .row-box {
    margin-top: 2vh;
    font-size: 16px;
  }

  .row-box .title  {
    width: 200px;
    display: block;
    margin: 10px auto;
    margin-bottom: 30px;
  }

  .footer-onboarding-page-container {
    font-size: 12px;
  }

  label {
    margin-left: 0rem;
  }

  .text-align-start {
    font-size: 16px;
  }

  .card-text {
    font-size: 14px;
  }

  .card-title {
    font-size: 28px;
    margin-left: 11%;
    margin-right: 11%;
    margin-bottom: 3%;
    margin-top: 3%;
  }

  .footer-onboarding-page-container .form-check .form-check-input {
    margin-left: 0;
  }
  
  .footer-onboarding-page-container .form-check  label {
    margin-left: 8px;
    text-align: left;
  }

  .card svg {
    height: 115px;
  }

  .card-body {
    text-align: center;
  }
}