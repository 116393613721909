.ant-modal-close-x svg {
    fill:  var(--custom-primary-background-color);
}

.modal-container img {
    width: unset;
    height: unset;
    max-width: 100%;
    padding: 20px;
}

.modal-buttons-container {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.attached-image-modal img {
    max-height: 50vh;
}

.modal-buttons-container button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 460px;
    width: 50%;
    height: 56px;
    border-radius: 10px;
    border: 2px solid var(--custom-secondary-background-color);
    background-color: white;
    padding: 0 27px;
    color: var(--custom-secondary-text-color);
}

.modal-buttons-container button:nth-child(2) {
    border: 2px solid var(--custom-primary-background-color);
    background-color: var(--custom-primary-background-color);
    color: var(--custom-primary-text-color) !important;
}

.modal-buttons-container button span {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; 
    font-family: 'TT-Norms-Regular';
}

.modal-buttons-container button img {
    width: 18px;
}

.attached-image-modal .modal-container {
    background: var(--card-background-color);
}

@media screen and (max-width: 600px) {
   .attached-image-modal .ant-modal-content {
        height: 100vh;
        padding-left: 0px;
        padding-right: 0px;
   }

   .attached-image-modal .attach-modal-instructions {
        padding: 10px;
        padding-left: 15px;
        font-size: 16px;
   }
   
   .attached-image-modal .ant-modal-content .ant-modal-body {
        height: 100%;
   }
   
   .attached-image-modal .ant-modal-content .modal-buttons-container {
        position: absolute;
        position: absolute;
        left: 0;
        width: 100%;
        padding: 15px;
   }

   .attached-image-modal .modal-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;
   }

    .modal-buttons-container button {
        padding: 0 13px;
    }
}