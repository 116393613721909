.container-box {
  background-color: var(--card-background-color);
  height: 85vh;
  border-radius: 28px;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-loading-box {
  height: 85vh;
  border-radius: 28px;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  text-align: center;
  font-family: 'TT-Norms-Regular';
  font-size: 18px;
}

.ant-modal .ant-modal-title {
  text-align: center;
  font-family: 'TT-Norms-Black';
  font-size: 24px;
  color: var(--text-color);
}

.button-personal-code {
  background-color: var(--custom-primary-background-color);;
  text-align: left;
  width: 50%;
}

.title-loading-documents {
  font-family: 'TT-Norms-Regular';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--alternative-text-color);
}

img {
  width: 100px;
  height: 100px;
}

.invalid-url {
  color: #FF1212 !important;
}

.icon-image {
  width: 14px;
  height: 14px;
  margin-right: 27px;
}

.btn-active {
  width: 80%;
  height: 48px;
  border-radius: 10px;
  background: var(--custom-primary-background-color);
  border-color: var(--custom-primary-background-color);
  font-family: 'TT-Norms-Regular';
  font-size: 18px;
  font-weight: 600;
  color: var(--custom-primary-text-color);
  white-space: nowrap;
  padding-right: 0px;
  padding-left: 0px;
}

.btn-active:hover,
.btn-active:first-child:active {
  background: var(--custom-primary-background-color-hover);
  border-color: var(--custom-primary-background-color-hover);
}

.btn-active:disabled {
  background: #D9D9D9;
  border-color: #D9D9D9;
  color: #9C9C9C;
}

.text-align-start {
  text-align-last: start;
}

.text-align-end {
  text-align-last: end;
}

.ant-input:focus,
.ant-input:hover {
  border: 1px solid var(--custom-primary-background-color);
  background: #FFF;
}

input {
  height: 40px;
  text-align: center;
  font-family: 'TT-Norms-Regular';
  font-size: 18px;
}

.error-message {
  color: #FF1212;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border: 1px solid #FF1212;
}

.error-title-personal-code .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #FF1212;
}

@media (max-width: 600px) {
  .container-box {
    margin-top: 1vh;
    height: 86vh;
  }

  .container-loading-box {
    margin-top: 1vh;
    height: 86vh;
  }

  .title-loading-documents {
    line-height: 20px;
    font-size: 18px;
  }

  .container-row {
    margin-right: 1px;
    margin-left: 1px;
  }

  .ant-modal .ant-modal-title {
    font-size: 20px;
  }

  .modal-container {
    font-family: 'TT-Norms-Regular';
    font-size: 16px;
  }
}

@media (max-width: 950px) and (orientation: landscape) {
  .container-box {
    margin-top: 1vh;
    height: auto;
  }

  .loading-box {
    height: 85vh;
    margin-top: 5vh;
  }
}