.container-sms-box-2 {
  background-color: var(--card-background-color);
  border-radius: 28px;
  margin-top: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'TT-Norms-Regular';
}

.review-numer-img {
  max-width: unset;
  width: 53%;
  height: 90%;
}

.audio-label {
  text-align: center;
  color: var(--custom-primary-background-color);
  font-family: 'TT-Norms';
  cursor: pointer;
}

.phone-number-input-disabled {
  text-align: left;
  font-family: 'TT-Norms';
  color: #585858;
  font-weight: 700;
  background-color: #EDEDED;
  border: 1px solid #EDEDED;
}

.new-code-label {
  color: var(--custom-primary-background-color);
  font-weight: 700;
  cursor: pointer;
}

.new-code-message {
  color: #9C9C9C;
}

.form-control.is-invalid {
  background-image: unset;
  border-color: #FF1212;
}

.form-control.is-invalid:focus {
  border-color: #FF1212;
  box-shadow: unset;
}

.align-error-message {
  text-align: end;
}

@media (max-width: 767px) {
  .phone-number-input-disabled {
    width: 90%;
    text-align: center;
  }

  .container-sms-box-2 {
    height: 65vh;
    padding: 0px 25px;
    margin-top: auto;
  }

  .review-numer-img {
    width: 150px;
    height: 150px;
  }

  .audio-label {
    text-align: center;
  }

  .align-error-message {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .sms-description {
    text-align: center;
  }
}

@media (max-width: 600px) or ((orientation: landscape) and (max-width: 1000px)) {
  .container-sms-box-2 {
    height: auto;
    padding: 30px;
  }

  .container-sms-box-2 svg {
    padding: 10px;
  }
}


