.qr-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.qr-container-reverse {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: unset;
}

.qr-container-reverse .qr-image {
    width: 261px;
    height: 261px;
    max-width: unset;
    padding: unset;
}

.qr-container .qr-image {
    width: 261px;
    height: 261px;
    max-width: unset;
}

.qr-container p {
    margin-left: 10px;
    text-align: left;
}

.qr-container-reverse p {
    margin-left: 10px;
    text-align: left;
}

.qr-instructions-title {
    font-weight: bold;
}
