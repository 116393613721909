@font-face {
  font-family: 'TT-Norms';
  src: url(./assets/fonts/tt-norms-medium-webfont.woff);
}

@font-face {
  font-family: 'TT-Norms-Regular';
  src: url(./assets/fonts/tt-norms-regular-webfont.woff);
}

@font-face {
  font-family: 'TT-Norms-Black';
  src: url(./assets/fonts/tt-norms-bold-webfont.woff);
}

body {
  margin: 0;
  font-family: 'TT-Norms-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: TT-Norms-Regular;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (prefers-color-scheme: light) {
  :root {
    --card-background-color: #F5F5F5;
    --text-color: black;
    --general-background-color: #FAFAFA;
    --alternative-text-color: #151A35;
  }

  body {
      background-color: white;
      color: black;
  }
}

/* Dark-mode */
@media (prefers-color-scheme: dark) {
  :root {
    --general-background-color: rgba(28, 28, 33, 1);
    --card-background-color: black;
    --text-color: white;
    --alternative-text-color: #FAFAFA;
  }

  body {
      background-color: var(--general-background-color);
      color: var(--text-color);
  }

  .ant-modal .ant-modal-content, .ant-modal .ant-modal-header, input{
    background-color:var(--card-background-color);
    color: var(--text-color);
  }

  .ant-modal .ant-modal-content {
    color: var(--text-color);
    border: 3px solid white;
  }

  .signatureCanvas {
    background-color: var(--card-background-color)!important;
  }

  .page-image-on-preview .form-check label {
    color: black;
  }
}