.success-hr {
    width: 80%;
    display: block;
    margin: 20px auto;
}

.green-text {
    color: var(--custom-primary-background-color);
    font-weight: bold;
}

.documents-list-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    gap: 3px;
    width: auto;
    min-width: 70%;
    padding: 0 11px!important;
}

.documents-list-button svg {
    margin-right: 0!important;
}

.exit-option {
    background-color: transparent;
    border: none;
    width: 50%;
    display: block;
    margin: auto;
    color: #9C9C9C;
    font-weight: bold;
}

.exit-button {
    width: 50%;
    display: block;
    margin: 80px auto 10px auto;
}

.documents-list-button svg {
    margin-right: 27px;
}

@media screen and (max-width: 1000px) {
    .documents-list-button, .exit-button {
        width: auto;
    }
}


@media screen and (max-width: 400px) {
    .documents-list-button {
        font-size: 14px;
    }
}